import { moneyFormat } from "@/functions";
export default {
  methods: {
    addFonts(doc) {
      const { BoldFont } = require("@/assets/fonts/boldfont.js");
      const { NormalFont } = require("@/assets/fonts/normalfont");
      doc.addFileToVFS("Bold.ttf", BoldFont);
      doc.addFont("Bold.ttf", "boldFont", "bold");
      doc.addFileToVFS("normal.ttf", NormalFont);
      doc.addFont("normal.ttf", "normalFont", "normal");
    },
    addCenter(doc, text, y) {
      var xOffset =
        doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / 2;
      doc.text(text, xOffset, y);
    },
    addRow(text, x, y, doc, qawsen = false) {
      let formattedText;
      if (text || text === 0) {
        if (typeof text === typeof 1) {
          formattedText = text.toFixed(3);
        } else if (!isNaN(Date.parse(text))) {
          formattedText = text;
        } else if (text.includes("$")) {
          formattedText = moneyFormat(text);
        } else {
          formattedText = text;
        }
      } else {
        formattedText = `$0.00`;
      }
      if (qawsen) {
        formattedText = `(${formattedText})`;
      }
      doc.text(`${formattedText}`, x, y);
    },
    rowList(space, startY = 0, startX = 0, mainX = 0, mainY = 0, arr, doc) {
      let y = startY + mainY,
        x = startX + mainX;
      arr.forEach(el => {
        //check if first in arr
        const ifChildIsInRow = typeof el[0] === typeof [];
        if (ifChildIsInRow) {
          el.forEach(ul => {
            this.addRow(ul[0], x + ul[1], y, doc, ul[2]||false);
          });
        } else {
          this.addRow(el[0], x + el[1], y, doc, el[2]||false);
        }
        y = y + space;
      });
    }
  }
};
